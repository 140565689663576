<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="归还单联想单号修改"/>
          <ChangeLenovoCode/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="未上传的归还单明细删除"/>
          <DeleteReturnOrderItem/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="异常借出单强行关闭"/>
          <DeleteLendOrder/>
        </el-row>
      </el-col>
      <el-col :span="12">
        <el-row class="row">
          <TableTitleComponent title="未上传的归还单转单"/>
          <ChangeUser/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="未上传的归还单明细改为已上传"/>
          <ChangeItemFinish/>
        </el-row>
        <el-row class="row">
          <TableTitleComponent title="待上传/部分上传的归还单强制关单"/>
          <ForceClose/>
        </el-row>
      </el-col>
    </el-row>
    <el-row :gutter="20">
      <TableTitleComponent title="未分拣借出单缺失数据维护"/>
      <LendOrderItemManage/>
    </el-row>
  </div>
</template>

<script>
import ForceClose from "@/views/returnOrder/ForceClose";
import TableTitleComponent from "@/components/TableTitleComponent";
import ChangeLenovoCode from "@/views/returnOrder/ChangeLenovoCode";
import ChangeUser from "@/views/returnOrder/ChangeUser";
import DeleteLendOrder from "@/views/lendOrder/DeleteLendOrder";
import DeleteReturnOrderItem from "@/views/returnOrder/DeleteReturnOrderItem";
import ChangeItemFinish from "@/views/returnOrder/ChangeItemFinish";
import LendOrderItemManage from "@/views/lendOrder/LendOrderItemManage";

export default {
  name: "DataManagement",
  components: {LendOrderItemManage, ChangeItemFinish, DeleteReturnOrderItem, DeleteLendOrder, ChangeUser, ChangeLenovoCode, TableTitleComponent, ForceClose}
}
</script>

<style scoped>
.row {
  padding-bottom: 20px;
}
</style>